import { useState } from "react";
const FaqTab = ({ title, Details }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div>
        <div className=" flex justify-between items-center cursor-pointer">
          <h3 className=" font-semibold text-xl leading-5 text-secondary dark:text-accent">
            {title}
          </h3>
          <button
            aria-label="too"
            className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={
                  isOpen ? "hidden stroke-primary" : "block stroke-primary"
                }
                d="M10 4.1665V15.8332"
                stroke="#ffff"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.16602 10H15.8327"
                className="stroke-primary"
                stroke="#ffff "
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <p
          className={
            "font-normal text-base leading-6 text-secondary dark:text-accent mt-4 w-11/12 " +
            (isOpen ? "block" : "hidden")
          }
        >
          {Details}
        </p>
      </div>
      <hr className=" my-7 bg-primary" />
    </>
  );
};

export default FaqTab;
