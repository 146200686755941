import { Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import "swiper/css";
import "swiper/css/pagination";
import Routers from "./Routes/Router";
import "./index.css";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "./themeContext";
import { CategoryProvider } from "./CategoryContext"
import "./Style/App.css";
import Loader from "./Components/Loader";
const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);
  return (
    <ThemeProvider className="!h-screen relative">
      <CategoryProvider>
        {loading === false ? (
          <Layout>
            <Routes>
              {Routers.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}
            </Routes>
          </Layout>
        ) : (
          <Loader />
        )}
      </CategoryProvider>
    </ThemeProvider>
  );
};

export default App;
