const TextArea = ({ formik, name, placeholder, value }) => {
  return (
    <textarea
    className="block w-full h-40 px-4 py-2 text-gray-700 bg-white
               border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
      name={name}
      placeholder={placeholder}
      value={value}
      {...formik.getFieldProps(name)}
    ></textarea>
  );
};

export default TextArea;
