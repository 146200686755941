import { Link } from "react-router-dom";
import "../Style/App.css";
const Products = ({ discounts, imgUrl, price, name, amazonlink }) => {
  return (
    <div
      id="parent"
      className="mt-4 w-max md:block  hover:cursor-pointer
          bg-transparent border-primary dark:bg-secondray hover:scale-110
           border-b border-l rounded-lg p-2 md:p-5 h-max mx-auto md:mx-2 hover:bg-primary
           hover:border-none  transition duration-300 ease-in-out"
    >
      <img src={imgUrl} alt="" className="w-52 h-60 rounded-lg" />
      <div className="flex justify-between items-center">
        <h1 id="titleChild" className="text-primary text-2xl font-bold">
          {name}
        </h1>
      </div>
      {/* <div className="flex my-2 justify-between"> */}
      <div className="flex justify-between">
        <div className="flex my-2 justify-between">
          <h5 className="text-black dark:text-accent">{price}</h5>
          <span id="titleChild" className="text-primary">
            {discounts}
          </span>
        </div>
        <button
          id="child"
          className="w-max  ml-2 px-2 py-1 mt-3 bg-primary rounded-md
                      hover:scale-110 
                      transition duration-300 ease-in-out hover:text-white"
        >
          <Link to="/ProductView">{amazonlink}</Link>
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Products;
