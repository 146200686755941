import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ToggleTheme } from "./ToggleTheme";
import ToggleLanguage from "./ToggleLanguage";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";
import NavLinks from "../Common/Navlink";
import Logo from "../Common/Logo";
import ProductsDropDown from "../Common/ProductsDropDown";
const Header = () => {
  return (
    <header className="relative">
      <Popover className="bg-transpaarent px-10">
        {/* BIG SCREEN HEADER */}
        <div className="max-w-7xl  px-6">
          <div className="flex items-center justify-between  border-gray-700 py-6 md:justify-start md:space-x-10">
            {/* Logo Division */}
            <Logo />
            {/* Hamburger Menu Button */}
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button
                className="inline-flex items-center
             justify-center rounded-md bg-transparent p-2 text-gray-400 
             hover:border border-primary hover:text-gray-500 focus:outline-none "
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-10 w-10" aria-hidden="true" />
              </Popover.Button>
            </div>
            {/* Menu Comp */}
            <div className="hidden sm:flex">
              <NavLinks to="/" name="Home" />
              {/* <NavLinks to="/Products" name={t("MenuProduct")} /> */}
              <ProductsDropDown />
              <NavLinks to="/AboutUS" name="About US" />
              <NavLinks to="/ContactUs" name="Contact US" />
              <NavLinks to="/Faq" name="FAQ" />
            </div>
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <ToggleLanguage />
              <ToggleTheme />
            </div>
          </div>
        </div>
        {/* LITTLE SCREEN HEADER */}
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className=" inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white dark:bg-black shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <Logo />
                  <div className="-mr-2">
                    <Popover.Button
                      className="inline-flex items-center justify-center rounded-md
                                   p-2  hover:text-primary hover:border border-primary"
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6 flex flex-col text-2xl">
                  <ProductsDropDown/>
                  <div className="">
                    <div className="grid grid-cols-2 mt-4  gap-x-2">
                      <NavLinks to="/AboutUS" name="About US" />
                      <NavLinks to="/ContactUs" name="Contact US" />
                      <NavLinks to="/Faq" name="FAQ" />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center mt-2">
                  <ToggleLanguage />
                  <ToggleTheme />
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};
export default Header;
