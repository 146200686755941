import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { ThemeProvider } from "../themeContext"

const Layout = ({ children }) => {
    return (
        <ThemeProvider className="!h-screen relative">
            <section className="bg-secondary dark:bg-secondary
             dark:text-accent relative h-screen">
                <Header />
                {children}
                <Footer />
            </section>
        </ThemeProvider >
    );
};

export default Layout;