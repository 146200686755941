import HomePage from "../Pages/HomePage.jsx";
import NotFound from "../Pages/NotFound.jsx";
import Faq from "../Pages/FaqPage.jsx";
import AboutUS from "../Pages/AboutUs.jsx";
import ContactUsPage from "../Pages/ContactUsPage.jsx";
import ProductsPage from "../Pages/ProductView.jsx"

const Routers = [
  { path: "*", element: <NotFound /> },
  {
    path: "/",
    indexRoute: {
      element: <HomePage />,
    },
    element: <HomePage />,
  },
  { path: "/Products", element: <ProductsPage /> },
  { path: "/Faq", element: <Faq /> },
  { path: "/AboutUs", element: <AboutUS /> },
  { path: "/ContactUs", element: <ContactUsPage /> },

];
export default Routers;
