import { useFormik } from "formik";
import { BsWhatsapp } from "react-icons/bs";
import Input from "../Common/Input";
import TextArea from "../Common/Textarea";
import React, { useRef } from "react";

import emailjs from "@emailjs/browser";

const ContactUsPage = () => {
  const initialValues = {
    FullName: "",
    Email: "",
    Phone: 0,
    explane: "",
  };

  const sbumitHandler = (value) => {
    console.log(value);

    emailjs

      .sendForm(
        "service_zdfp5bh",

        "template_96bj3xh",

        form.current,

        "qaxVbETjQlVdrOHov"
      )

      .then(
        (result) => {
          console.log(result.text);
        },

        (error) => {
          console.log(error.text);
        }
      );
  };

  const formik = useFormik({
    initialValues,

    onSubmit: sbumitHandler, // validationSchema,
  });

  const form = useRef();

  return (
    <section className=" mx-20 mt-5 rounded-lg">
      <div className="grid grid-cols-1  md:grid-cols-2 gap-10  p-10">
        {/* div data */}
        <div>
          <h1 className="font-bold text-4xl dark:text-white">Get In Touch</h1>
          <div className="bg-orange-600 w-32 h-1 rounded-2xl mt-5 flex"></div>
          <p className="font-semibold text-gray-500 dark:text-gray-400 mt-5">
            Kontakt
          </p>

          <div className="mt-10">
            <div className="flex items-center dark:text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />

                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              Lüstringer Straße 10, 49143
            </div>

            <div className="flex items-center dark:text-white mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              info@aval-co.de
            </div>
            <div className="flex items-center dark:text-white mt-5">
              <BsWhatsapp className="w-6 h-6 mx-2" />
              +49 1772416238
            </div>

            <div className="flex items-center dark:text-white mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 8l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                />
              </svg>
              +49 5402 9789979
            </div>
          </div>
        </div>
        {/* form data */}
        <div>
          <div className="mt-6">
            <form
              ref={form}
              onSubmit={formik.handleSubmit}
              className="items-center -mx-2 flex flex-col"
            >
              <div className="flex flex-col md:flex-row w-full">
                <Input name="FullName" placeholder="FullName" formik={formik} />
                <Input name="Email" placeholder="Email" formik={formik} />
              </div>

              <div className="w-full mt-4">
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                  explanation
                </label>
                <TextArea
                  formik={formik}
                  name="explanation"
                  placeholder="Explanation"
                />
              </div>
              <div className="flex justify-center mt-6">
                <button
                  type="submit"
                  className="px-4 py-2 text-white transition-colors duration-200 transform
               bg-orange-600 rounded-md hover:bg-orange-500 focus:outline-none focus:bg-orange-300"
                >
                  Send Response
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsPage;
