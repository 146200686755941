import React, { useEffect } from "react";
import { CategoryContext } from "../CategoryContext";
import http from "../Service/Axios.config";
import Products from "../Common/Products";
import { useState } from "react";
const ProductsPage = () => {
  const { CategoryId } = React.useContext(CategoryContext);
  const [product, setproduct] = useState([]);
  console.log(CategoryId);
  useEffect(() => {
    http
      .get(`/GetAllProduct.php?category_id=${CategoryId}`)
      .then((Res) => {
        console.log(Res.data);
        setproduct(Res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      {product.map((item) => (
        <Products
          name={item.name}
          price={item.price}
          imgUrl={item.img}
          amazonlink={item.A_link}
        />
      ))}
    </div>
  );
};

export default ProductsPage;
