import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineTranslate } from "react-icons/hi";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import i18n from "i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ToggleLanguage = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <Menu as="div" className="relative text-black dark:text-white text-left">
      <div>
        <Menu.Button
          className="inline-flex w-full justify-center 
          bg-transparent  
          px-4 py-2 text-sm font-medium text-secondary dark:text-accent
             focus:outline-none text-black dark:text-white"
        >
          <HiOutlineTranslate className="text-xl text-black dark:text-white" />
          <span className="text-black dark:text-white">ln</span>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-black" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-56 text-secondray dark:text-accent
           origin-top-right
         rounded-md bg-blacks border border-primary shadow-lg
          ring-1 ring-black bg-white dark:bg-black  ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-primary  rounded-md text-black dark:text-white" : "text-black dark:text-white",
                    " px-4 py-2 text-sm flex cursor-pointer w-full  "
                  )}
                  onClick={(e) => changeLanguage("pr")}
                >
                  {/* <BsFillSunFill className="mx-3 text-xl" /> */}
                  Persian
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-primary  rounded-md text-black dark:text-white" : "text-black dark:text-white",
                    " px-4 py-2 text-sm flex cursor-pointer w-full"
                  )}
                  onClick={(e) => changeLanguage("en")}
                >
                  English
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-primary  rounded-md text-black dark:text-white" : "text-black dark:text-white",
                    " px-4 py-2 text-sm flex cursor-pointer w-full"
                  )}
                  onClick={(e) => changeLanguage("gr")}
                >
                  {/* <BsMoonStarsFill className="mx-3 text-xl" /> */}
                  Germany
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default ToggleLanguage;
