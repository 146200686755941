import React from 'react';

export const CategoryContext = React.createContext()

export const CategoryProvider = ({ initialTheme, children }) => {
    const [CategoryId, setCategoryId] = React.useState(0)


    return (
        <CategoryContext.Provider value={{ CategoryId, setCategoryId }}>
            {children}
        </CategoryContext.Provider>
    )
}