import axios from "axios";
axios.defaults.baseURL = "https://aval-co.de/server/";
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
const http = {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
};
export default http;
