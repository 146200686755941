import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css/pagination";
import "../Style/App.css";
import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
const HomePage = () => {
  const { t} = useTranslation();

  return (
    <section
      className="flex flex-col
      justify-center items-end"
    >
      <div className="flex items-start mx-3 mb-2 md:hidden">
        <button className="border border-white p-2 mx-2 rounded-full">
          <HiArrowNarrowLeft />
        </button>
        <button className="border border-white p-2 mx-2 rounded-full">
          <HiArrowNarrowRight />
        </button>
      </div>
      <Swiper
        grabCursor={true}
        modules={[Pagination]}
        className="mySwiper top-0 relative"
      >
        {/* Slide 1 */}
        <SwiperSlide
          className="
                 w-full h-screen bg-cover bg-no-repeat
                bg-center !bg-safran"
        >
          <div className="flex flex-col text-primary p-10">
            <h1 className="text-white text-2xl font-bold">{t("SlideTitle")}</h1>
            {/* <h5> {t("SlideOneText")}</h5> */}
          </div>
        </SwiperSlide>
        {/* Slide 2 */}
        <SwiperSlide
          className=" flex md:flex-row flex-col pl-4 md:pl-0
                 w-full h-screen bg-cover bg-no-repeat bg-center !bg-newbg"
        >
          <div className="flex mt-2 top-4 md:top-14 w-54 flex-col md:text-left md:left-14 text-primary absolute">
            <h1 className="text-white text-2xl font-bold">{t("SlideTitle")}</h1>
            {/* <p className="text-[#b07a04] w-56 mt-3">{t("SlideTwoText")}</p> */}
          </div>
        </SwiperSlide>
        {/* Slide 3 */}
        <SwiperSlide className=" flex md:flex-row flex-col ">
          <div className="flex flex-col md:mb-20 w-96">
            <div className="mx-10 mt-2 md:mt-10">
              <h1 className="text-primary text-3xl font-bold">
                {t("SlideTitle")}
              </h1>
              <p className="text-sm text-[#858583] mt-3  md:mt-20">
                <h2 className="text-black text-xl dark:text-white">
                  {" "}
                  {t("SlideThreeText")}
                  <Link to="/AboutUS" className="text-primary">
                    AboutUS
                  </Link>
                </h2>
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
export default HomePage;
