const Footer = () => {
  return (
    <footer className="text-center flex justify-center items-center bg-transparent text-white">
      <div
        className="text-center text-black dark:text-white p-4"
      >
        © 2022 Copyright:{" "}
        <a className="text-primary" href="https://fanavar.info/">
          Fanavar.info
        </a>
      </div>
    </footer>
  );
};

export default Footer;
