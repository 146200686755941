import React from "react";
import { useTranslation } from "react-i18next";
const AboutUS = () => {
  const { t } = useTranslation();

  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full lg:w-5/12 flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-primary pb-4">
            {t("AboutUsTitle")}
          </h1>
          <p className="font-normal text-base leading-6 text-gray-600 ">
            {t("AboutUsPraghraph")}
          </p>
        </div>
        <div className="w-full lg:w-8/12 ">
          <img
            className="w-full h-full"
            src="https://i.ibb.co/FhgPJt8/Rectangle-116.png"
            alt="A group of People"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUS;
