const Loader = () => {
  return (
    <div className="flex justify-center items-center mt-40">
      <div className="flex flex-col items-center justify-center">
        <div className="mt-10 w-40 h-40 border-t-4 border-b-4 border-primary rounded-full animate-spin"></div>
        <h1 className="text-4xl mt-10 text-black dark:text-white">Loading ...</h1>
      </div>
    </div>
  );
};

export default Loader;
