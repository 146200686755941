import React, { useEffect, useState } from "react";
import FaqTab from "../Common/FaqTab";
import http from "../Service/Axios.config";
const FaqPage = () => {
  const [faqdata, setFaqdata] = useState([]);
  useEffect(() => {
    http
      .get(`/GetFaqData.php`)
      .then((res) =>{setFaqdata(res)})
      .catch((error) => console.log(error));
  }, []);
  // setFaq([{title:"msni",describtion:"msit "}])
  console.log(faqdata);
  return (
    <div className=" 2xl:container 2xl:mx-auto   md:py-12 lg:px-20 md:px-6 py-9 px-4">
      <h2 className=" font-semibold lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-secondary dark:text-accent">
        Häufig gestellte Fragen
      </h2>
      <div className="mt-4 flex md:justify-between md:items-start md:flex-row flex-col justify-start items-start">
        <div className="">
          <p className=" font-normal text-base leading-6 text-secondary dark:text-accent lg:w-8/12 md:w-9/12 ">
            Hier sind einige der am häufigsten gestellten Fragen unserer
            geschätzten Kunden
          </p>
        </div>
      </div>
      <div className=" flex md:flex-row flex-col md:space-x-8 md:mt-16 mt-8">
        <div className=" md:w-7/12 lg:w-8/12 w-full md:mt-0 sm:mt-14 mt-10">
          {faqdata.map((data) => (
            <FaqTab
              key={data.id}
              title={data.Question_EN}
              Details={data.Answer_EN}
            />
          ) )}
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
