import { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { GiCoolSpices } from "react-icons/gi";
import { CategoryContext } from "../CategoryContext";
import { Link } from "react-router-dom";
import http from "../Service/Axios.config";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ProductsDropDown = () => {
  const { CategoryId, setCategoryId } = React.useContext(CategoryContext);
  const [Categorys, setCategory] = useState([]);
  useEffect(() => {
    http
      .get("/GetPrCategory.php")
      .then((res) => setCategory(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={(navData) =>
              navData.isActive
                ? "text-primary link-underline link-underline-black flex focus:outline-none  leading-tight text-base font-medium"
                : "text-gray-500 link-underline link-underline-black flex focus:outline-none  leading-tight text-base font-medium"
            }
          >
            <span>Products</span>
            <ChevronDownIcon
              className={classNames(
                open ? "text-gray-600" : "text-gray-400",
                "ml-2 h-5 w-5 group-hover:text-gray-500"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-96 max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white dark:bg-black px-5 py-6 sm:gap-8 sm:p-8">
                  {Categorys.map((item) => (
                    <Link
                      to="Products"
                      key={item.id}
                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-900 hover:text-white"
                      onClick={(event) => setCategoryId(item.id)}
                    >
                      <div className="ml-4 flex items-center justify-center">
                        <GiCoolSpices className="w-8 h-8 text-primary"/>

                        <p className="text-base font-medium dark:text-white mx-7">
                          {item.name_EN}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ProductsDropDown;
