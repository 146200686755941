const Input = ({ formik, name, placeholder, value }) => {
  return (
    <div class="w-full mx-1">
      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
        {placeholder}
      </label>
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        {...formik.getFieldProps(name)}
        n
        className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
        value={value}
      />
      {formik.errors[name] && formik.touched[name] && (
        <div className="text-red-600 font-semibold ">{formik.errors[name]}</div>
      )}
    </div>
  );
};

export default Input;
