import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="flex justify-start lg:w-0 lg:flex-1">
      <Link to="/"
        class="before:block
                           before:absolute before:-inset-1 mb-1
                           before:-skew-y-2 before:bg-primary relative my-1"
      >
        <span class="relative text-white text-3xl">Aval</span>
      </Link>
      {/* <img src="../img/logo.jpg" classNa  e="w-10 h-10 mx-4" alt="logo" /> */}

    </div>
  );
};

export default Logo;
