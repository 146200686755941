import React, { Fragment } from "react";
import { ThemeContext } from "../themeContext";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BsMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { useTranslation } from "react-i18next";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ToggleTheme = () => {
  // use Context
  const { theme, setTheme } = React.useContext(ThemeContext);
  // Check the Theme
  function isDark() {
    return theme === "dark";
  }
  const { t, i18n } = useTranslation();

  return (
    <Menu as="div" className="relative  text-left">
      <div>
        <Menu.Button
          className="inline-flex w-full justify-center rounded-md border
         border-primary bg-transparent  
          px-4 py-2 text-sm font-medium text-secondary dark:text-accent
           shadow-sm focus:outline-none "
        >
          <HiColorSwatch className="text-xl" />
          Theme
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-56 
           origin-top-right
         rounded-md bg-blacks border border-primary
          ring-1 ring-black bg-white text-black dark:text-white dark:bg-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-primary  rounded-md text-black dark:text-white" : "text-black dark:text-white",
                    " px-4 py-2 text-sm flex cursor-pointer w-full"
                  )}
                  onClick={(e) => setTheme("light")}
                >
                  <BsFillSunFill className="mx-3 text-xl" />
                  light
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-primary  rounded-md text-black dark:text-white" : "text-black dark:text-white",
                    " px-4 py-2 text-sm flex cursor-pointer w-full"
                  )}
                  onClick={(e) => setTheme("dark")}
                >
                  <BsMoonStarsFill className="mx-3 text-xl" />
                  dark
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
