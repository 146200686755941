import { NavLink } from "react-router-dom";
const NavLinks = ({ name, to }) => {
  return (
    <NavLink
      to={to}
      className={(navData) =>
        navData.isActive
          ? "text-primary link-underline link-underline-black leading-tight text-base font-medium"
          : "text-gray-500 link-underline link-underline-black leading-tight text-base font-medium"
      }
    >
      {name}
    </NavLink>
  );
};

export default NavLinks;
