import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      //Header
      MenuHome: "Home",
      MenuProduct: "Products",
      MenuAboutUs: "AboutUs",
      MenuContactUs: "ContactUs",
      MenuFaq: "Faq",
      ThemeTitle: "Theme",
      ThemeDark: "Dark",
      ThemeLight: "Light",
      // Home Page
      SlideTitle: "Aval International Business",
      SlideOneText:
        "The largest importer of products such as saffron, spices and",
      SlideTwoText: "The most reliable importer",
      SlideThreeText:
        "Aval Intrnational Business Founded by ..... in the year ..... with the aim of buying products from the origin of production …",
      // Products page
      CategoryTitle: "Select Product category :",
      // Category
      Food: "Food",
      Figure: "Figure",
      handicrafts: "handicrafts",
      Supplements: "Supplements",
      // About us
      AboutUsTitle: "About Us",
      AboutUsPraghraph:
        "Our company imports products from Iran that are either produced directly in our workshops or bought carefully and fairly. Our spice blends are of excellent quality. Our saffron is bought sorted by our experts directly at the bazaar, as the quality of the entire product is very important to us. All of our handmade products are of high quality and are specially made to order for our company. Our commissioned artists are paid fairly because we know how valuable their work and time is.",
    },
  },
  gr: {
    translation: {
      // Menu
      MenuHome: "Home",
      MenuProduct: "Produkte",
      MenuAboutUs: "Über uns",
      MenuContactUs: "Kontakt",
      MenuFaq: "Faq",
      ThemeTitle: "Thema",
      ThemeDark: "darj",
      ThemeLight: "hell",
      //Home
      SlideTitle: "Aval International Business",
      SlideOneText:
        "Der größte Importeur von Produkten wie Safran, Gewürzen und",
      SlideTwoText: "Der zuverlässigste Importeur... (Productlink)",
      SlideThreeText:
        "Importeur von Safran und Gewürzen aus Asien und dem Iran in Ihren Händen in Deutschland Aval International Business Gegründet von ..... im Jahr ..... mit dem Ziel…..",
      // Products
      CategoryTitle: "Wählen Sie Produktkategorie :",
      //Category
      Food: "Lebensmittel",
      Figure: "Holzfiguren",
      handicrafts: "Kunsthandwerk",
      Supplements: "Ergänzungen",
      // About us
      AboutUsTitle: "über uns",
      AboutUsPraghraph:
        "Unser Unternehmen importiert Produkte aus dem Iran, die entweder direkt in unseren Werkstätten produziert werden oder sorgfältig und fair gekauft werden. Unsere Gewürzmischungen haben eine hervorragende Qualität. Unser Safran wird von unseren Experten direkt auf dem Basar sortiert gekauft, da uns die Qualität der gesamten Ware sehr wichtig ist. Alle unserer handgefertigten Produkte sind hochwertig und werden speziell im Auftrag für unser Unternehmen hergestellt. Unsere beauftragten Künstler werden fair bezahlt, da wir wissen, wie wertvoll deren Arbeit und deren Zeit ist.",
    },
  },
  pr: {
    translation: {
      // Menu
      MenuHome: "خانه",
      MenuProduct: "محصولات",
      MenuAboutUs: "درباره ما",
      MenuContactUs: "ارتباط با ما",
      MenuFaq: "سوالات متداول",
      ThemeTitle: "طرح رنگی",
      ThemeDark: "تیره",
      ThemeLight: "روشن",
      // Home
      SlideTitle: "Aval International Business",
      SlideOneText:
        "Der größte Importeur von Produkten wie Safran, Gewürzen und",
      SlideTwoText: "قابل اعتماد ترین وارد کننده .. د محصولات",
      SlideThreeText:
        "Aval Intrnational Business  در سال .....  با هدف خرید محصولات از مبدا تولید... ",
      // Products
      // Products Page
      CategoryTitle: "دسته بندی مورد نظر را انتخواب کنید :",
      // Category
      Food: "محصولات غذایی",
      Figure: "فیگور",
      handicrafts: "صنایع دستی",
      "Pharmaceutical Supplements": "مکمل های غذایی",
      // AboutUs
      AboutUsTitle: "درباره ما",
      AboutUsPraghraph:
        "شرکت ما محصولاتی را از ایران وارد می کند که یا مستقیماً در کارگاه های ما تولید می شوند و یا با دقت و منصفانه خریداری می شوند. مخلوط ادویه های ما با کیفیت عالی هستند. زعفران ما توسط کارشناسان ما مستقیماً در بازار طبقه بندی می شود، زیرا کیفیت کل محصول برای ما بسیار مهم است. تمام محصولات دست ساز ما با کیفیت بالا هستند و به طور خاص برای سفارش شرکت ما ساخته شده اند. هنرمندان سفارش شده ما حقوق منصفانه ای دریافت می کنند زیرا می دانیم کار و زمان آنها چقدر ارزشمند است.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "gr",

  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
